<template>

	<div :style="`height: 100vh;`">

		<!-- 后台面板功能 -->
		<div :style="`display: flex;overflow: hidden;`">

			<!-- 侧边菜单导航 -->
			<div id="menu" :style="`width: 190px;`">
				
				<!-- 侧边菜单 -->
				<!-- 此处必须使用v-if判断以下菜单数据是否到达，为了菜单中的name与当前路由页面的路径一直，也为了active-name与当前路由页面之一 -->
				<Menu ref="menu"   style="height: 100%;width: 190px;"
					theme="dark" :active-name="$route.path">
					
					<MenuGroup title="授权管理后台管理系统">
				
						<MenuItem v-show="big_show_config.indexOf(userInfo.type)!=-1" to="/user_settings" name="/user_settings">
							<Icon type="md-chatbubbles" />
							用户设置
						</MenuItem>
						<MenuItem v-show="big_show_config.indexOf(userInfo.type)!=-1" to="/application_management" name="/application_management">
							<Icon type="md-chatbubbles" />
							应用管理
						</MenuItem>
						<MenuItem v-show="big_show_config.indexOf(userInfo.type)!=-1" to="/wxpay" name="/wxpay">
							<Icon type="md-chatbubbles" />
							微信支付
						</MenuItem>
						<MenuItem v-show="big_show_config.indexOf(userInfo.type)!=-1" to="/third_party_platform" name="/third_party_platform">
							<Icon type="md-chatbubbles" />
							第三方平台
						</MenuItem>
						
					</MenuGroup>

				</Menu>

			</div>

			<!-- 内容区域 -->
			<div :style="`position: relative;height:100vh; z-index: 99;;overflow-x: hidden;width: 100%;`">
				
				<!-- 导航页右侧 -->
				<div>
					
					
					<div style="z-index: 999; position: fixed; top: 0;right: 0;left: 190px; padding: 0 10px; justify-content: space-between; height: 60px;background-color: #fff; display: flex;align-items: center;">
						
						<!-- 退出系统 -->
						<div :style="`z-index: 10;display: flex;justify-content: space-between;width: 100%;`">
							<div></div>
							<div>
								用户： {{$userInfo.name}}<Button style="margin: 0 10px;" @click="reset_new_pwd = true"
									type='error'>修改密码</Button>
								<Button @click="()=>to_logout()" type="primary">退出</Button>
							</div>
						</div>
					
					</div>
					
				</div>
				
				<!-- 后台内容显示区域 -->
				<div class="bd" style="height: 100vh; padding-top: 75px;overflow-y: scroll;padding: 100px 30px 0 30px;background-color: #efefef;">

					<!--侧边导航 欢迎页信息 -->
					<div v-if="$route.path=='/home'">
						<h1 style="font-weight: 700;">欢迎使用授权管理后台管理系统</h1>
					</div>
					
					<!--侧边导航 其他页信息 -->
					<router-view />
					
				</div>

			</div>

		</div>

		<!-- 密码修改对话框 -->
		<Modal :mask-closable='false'  v-model='reset_new_pwd' title='修改密码'>

			<p>
				<span style="display: inline-block;width: 100px;">旧密码：</span>
				<Input type='password' style="width: 300px;" v-model="old_pwd"></Input>
			</p>
			<p style="margin-top: 20upx;">
				<span style="display: inline-block;width: 100px;">新密码：</span>
				<Input type='password' style="width: 300px;" v-model="new_pwd"></Input>
			</p>
			<p style="margin-top: 20upx;">
				<span style="display: inline-block;width: 100px;">新密码确认：</span>
				<Input type='password' style="width: 300px;" v-model="new_pwd_copy"></Input>
			</p>
			<div slot='footer'>
				<Button @click="()=>reset_new_pwd=false">返回</Button>
				<Button @click="()=>do_reset_new_pwd()" type='primary'>确定</Button>
			</div>

		</Modal>

	</div>

</template>
<script>
	
	import { banner } from '../assets/json/banner.js'
	import { logout,UpPass } from '../request/api.js'
	import { md5 } from '../lib/js/common/md5.js'
	export default {
		name: '页面_首页',
		data() {

			return {
				big_show_config:["超级管理","管理员"],
				userInfo:'',
				new_pwd_copy: '', //iput新密码确认
				reset_new_pwd: false, //控制修改密码对话框显示与隐藏
				old_pwd: '', //input旧密码
				new_pwd: '', //input新密码
				open_menu: '', //打开的菜单
				select_stores: '', //当前门店
				background_menu: [], //侧边导航菜单
			}

		},
		async mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			this.background_menu = banner

		},
		methods: {


			async do_reset_new_pwd() {
				
				if(this.new_pwd==''||this.new_pwd_copy==''||this.old_pwd==''){
					this.$mytoast_err('请正确输入')
					return
				}
				if(this.new_pwd_copy!=this.new_pwd){
					this.$mytoast_err('新密码两次输入不一致')
					return
				}
				
				let pass_data = {
					"pass": md5(this.old_pwd), 
					"Npass": md5(this.new_pwd_copy) 
				}
				
				let res_data = await UpPass(pass_data)
				
				if(res_data.code==200){
					this.reset_new_pwd = false
					this.$mytoast(res_data.msg)
				}
				
			},

			// 退出登录
			async to_logout() {
				
				let token = localStorage.getItem('token')
				let pass_data = {
					token
				}
				console.log(token)
				let res_data = await logout(pass_data)
				
				if(res_data.code==200){
					localStorage.clear()
					this.$router.push('/')
				}
				
			}

		}
	}
</script>
<style type="text/css">
	.bd::-webkit-scrollbar {
		width: 0;
	}
</style>
