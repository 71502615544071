<template>
	<div>
		<Card style="margin-bottom: 10px;">
			<Form label-position='left' :label-width="130">
				<FormItem label="服务商">
					{{ComponentInfo.name}}
				</FormItem>
				<FormItem style="color: green;" label="状态">
					{{ComponentInfo.state}}
				</FormItem>
				<FormItem label="appid">
					{{ComponentInfo.APPID}}
				</FormItem>
				<FormItem label="应用接入量">
					{{ComponentInfo.appnum}}/{{ComponentInfo.appmax}}
				</FormItem>
				<FormItem label="调用key创建量">
					{{ComponentInfo.keynum}}/{{ComponentInfo.keymax}}
				</FormItem>

			</Form>
			<div style="position: absolute;top: 10px;right: 10px;color: #ccc">
				{{ComponentInfo.creationTime}}
			</div>
			<div style="position: absolute;top: 50px;right: 10px;color: #ccc">
				<Button @click="()=>$my_copy(ComponentInfo.ComponentVerifyTicket)">复制票据</Button>
			</div>
			<div style="position: absolute;top: 100px;right: 10px;color: #ccc">
				<Button @click="()=>to_ComponentAccessToken()">获取凭证</Button>
			</div>
			<div style="position: absolute;top: 150px;right: 10px;color: #ccc">
				<Button @click="()=>to_ComponentAuthAppSync()">同步应用</Button>
			</div>
			<div style="position: absolute;top: 200px;right: 10px;color: #ccc">
				<Button @click="()=>to_ParytclearCache()">清除缓存</Button>
			</div>
		</Card>
		<Card>
			<Tabs type="card">
				<TabPane label="基本配置">
					<div style="width: 500px;">
						<Form label-position='left' :label-width="130">
							<FormItem label="AppSecret">
								<div style="display: flex;align-items: center;">
									<Input v-model="ComponentInfo.AppSecret"></Input>
									<Icon @click="()=>$my_copy(ComponentInfo.AppSecret)"
										style="cursor: pointer;margin: 0 20px;" type="ios-browsers-outline" />
								</div>
							</FormItem>
							<FormItem label="Token">
								<div style="display: flex;align-items: center;">
									<Input v-model="ComponentInfo.Token"></Input>
									<Icon @click="()=>$my_copy(ComponentInfo.Token)"
										style="cursor: pointer;margin: 0 20px;" type="ios-browsers-outline" />
								</div>

							</FormItem>
							<FormItem label="EncodingAESKey">
								<div style="display: flex;align-items: center;">
									<Input v-model="ComponentInfo.EncodingAESKey"></Input>
									<Icon @click="()=>$my_copy(ComponentInfo.EncodingAESKey)"
										style="cursor: pointer;margin: 0 20px;" type="ios-browsers-outline" />
								</div>
							</FormItem>
						</Form>
					</div>
					<div style="line-height: 50px;text-align: right;">
						<Button @click="()=>to_ComponentSetConfig_base()">提交</Button>
					</div>
				</TabPane>
				<TabPane label="IP配置">
					 <div style="margin-bottom: 10px;">在IP白名单内的IP地址作为来源，应用接口才可调用成功，需要配置多个,输入IP后进行换行输入</div>
					 <Input v-model="ComponentInfo.whiteList"  type="textarea" :rows="10" placeholder="Enter something..." />
					 <div style="line-height: 50px;text-align: right;">
						 <Button  @click="()=>to_ComponentSetConfig()">提交</Button>
					 </div>
				</TabPane>
				<TabPane label="授权应用">
					<Form label-position='left' :label-width="130">
						<FormItem label="应用状态">
							<Select v-model="state"   style="margin-right: 10px;  width: 250px;">
								<Option v-for="item in auth_app_config" :key="item" :value="item" >{{item}}</Option>
							</Select>
							<Button style="margin-right: 10px;" @click="to_GetAuthAppList()">确认</Button>
							<Button style="margin-right: 10px;" @click="fastlogin_modal=true">快速注册</Button>
							<Button @click="url_modal=true">预授权URL</Button>
						</FormItem>
					</Form>
					<Table style="margin-top: 10px;" :columns="AuthAppList_header" :data="AuthAppList_list">
						<template slot-scope="{row}" slot="image_url">
							<div style="width: 50px;height: 50px;">
								<img style="width: 100%;height: 100%;" :src="row.image_url" alt="">
							</div>
						</template>
						<template slot-scope="{row}" slot="operation">
							
							<Button v-if="row.state=='已授权'" @click="()=>to_GetAuthAppsf('获取access_token',row)" style="margin-right: 10px;" size="small">获取access_token</Button>
							<Button v-if="row.state=='已授权'" @click="()=>to_GetAuthAppsf('获取应用信息',row)" size="small">获取应用信息</Button>
							<Button v-if="row.state=='创建中'" @click="()=>to_GetAuthAppsf('查询进度',row)" size="small">查询进度</Button>
							<Button v-if="row.state=='授权取消'" @click="()=>to_GetAuthAppsf('删除',row)" size="small">删除</Button>
						</template>
					</Table>
				</TabPane>
				<TabPane label="apikey">
					<div style="line-height: 50px;text-align: right;">
						<Button @click="()=>add_modal=true">添加</Button>
					</div>
					<div style="display: flex;flex-wrap: wrap;">
						<Card style="margin-right: 10px;" class="apikey" v-for="item in ComponentInfo.keylist">
							<Form label-position='left'>
								<FormItem label="">
									{{item.name}}
								</FormItem>
								<FormItem  label="">
									sid：{{item.sid}}<Icon @click="()=>$my_copy(item.sid)"
										style="cursor: pointer;margin: 0 20px;" type="ios-browsers-outline" />
								</FormItem>
								<FormItem label="">
									apikey：{{item.apikey}}<Icon @click="()=>$my_copy(item.apikey)"
										style="cursor: pointer;margin: 0 20px;" type="ios-browsers-outline" />
								</FormItem>
							</Form>
							<div style="position: absolute;top: 10px;right: 10px;">
								<i-Switch @on-change="($)=>$?to_ComponentApiKey_other(item,'开启'):to_ComponentApiKey_other(item,'关闭')" v-model="item.state"></i-Switch>
								<Button @click="()=>(del_modal=true)&&(current_row=item)" size="small" style="margin-left: 10px;">删除</Button>
							</div>
						</Card>
					</div>
				</TabPane>
				<TabPane label="消息日志">
					<Button @click="()=>to_ComponentGetmsglog()">获取日志</Button>
					<Button style="margin-left: 20px;" @click="()=>Removelog_dialog=true">清除日志</Button>
					<Table style="margin-top: 10px;" :columns="Getmsglog_header" :data="Getmsglog_list">
						<template slot-scope="{row}" slot="decrypbody">
							<pre>
								{{row.decrypbody}}
							</pre>
						</template>
					</Table>
				</TabPane>
			</Tabs>
		</Card>
		<Modal @on-visible-change="()=>handleReset('fastlogin')" v-model="fastlogin_modal">
			<div style="width: 300px;">
				<Form ref="fastlogin" label-position='left' :model="fastlogin" :label-width="130" :rules="ruleValidate">
					<FormItem prop="name" label="企业名">
						<Input v-model="fastlogin.name"></Input>
					</FormItem>
					<FormItem prop="code" label="企业代码">
						<Input v-model="fastlogin.code"></Input>
					</FormItem>
					<FormItem prop="legal_persona_wechat" label="法人微信号">
						<Input v-model="fastlogin.legal_persona_wechat"></Input>
					</FormItem>
					<FormItem prop="legal_persona_name" label="法人姓名">
						<Input v-model="fastlogin.legal_persona_name"></Input>
					</FormItem>
					<FormItem prop="persona_phone" label="法人手机号">
						<Input v-model="fastlogin.persona_phone"></Input>
					</FormItem>
					<FormItem prop="component_phone" label="第三方联系电话">
						<Input v-model="fastlogin.component_phone"></Input>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button @click="()=>fastlogin_modal=false">取消</Button>
				<Button @click="()=>to_QuicklyCreateApp()">确认</Button>
			</div>
		</Modal>
		<Modal fullscreen v-model="app_info_modal">
			<pre>
				{{app_info}}
			</pre>
			<div slot="footer"></div>
		</Modal>
		<Modal v-model="del_modal">
			<div>	
				确认是否删除？
			</div>
			<div slot="footer">
				<Button @click="()=>del_modal=false">取消</Button>
				<Button @click="()=>(to_ComponentApiKey_other(current_row,'删除'))&&(del_modal=false)">确定</Button>
			</div>
		</Modal>
		<Modal v-model="add_modal">
			
			<Form  label-position='left'>
				<FormItem label="名称">
					<Input style="width: 250px;" v-model="name"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="()=>add_modal=false">取消</Button>
				<Button @click="()=>to_ComponentApiKey()">确定</Button>
			</div>
		</Modal>
		<Modal v-model="Removelog_dialog">
			<div>确认是否清除</div>
			<div slot="footer">
				<Button @click="()=>Removelog_dialog=false">返回</Button>
				<Button @click="()=>to_Removelog()">确定</Button>
			</div>
		</Modal>
		<Modal :mask-closable='false' v-model="url_modal">
			<Form :label-width="130"  label-position='left'>
				<FormItem label="小程序的appid">
					<Input style="width: 250px;" v-model="ParyAuthURL_data.bizappid"></Input>
				</FormItem>
				<FormItem  label="指定的权限集">
						<Input style="width: 250px;" v-model="ParyAuthURL_data.clist"></Input>
						<Button style="margin-left: 10px;" @click="()=>to_ParyAuthURL()">生成url</Button>
				</FormItem>
				<FormItem >
					<Input  type="textarea" :rows="5" style="width: 250px;" v-model="ParyAuthURL_data.auth_url"></Input>
					<Button style="margin: 30px 0 0 10px;" @click="()=>$my_copy(ParyAuthURL_data.auth_url)">复制</Button>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="()=>url_modal=false">取消</Button>
				<!-- <Button @click="()=>to_ParyAuthURL()" >确定</Button> -->
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		QuicklyCreateApp,
		GetComponentInfo,
		ComponentApiKey,
		ComponentSetConfig,
		GetAuthAppList,
		GetAuthAppsf,ComponentGetmsglog,
		ComponentAccessToken,
		ComponentAuthAppSync,
		ParytclearCache,
		ParyAuthURL,
		Removelog
	} from '@/request/api'
	export default {
		name: 'manage',
		data() {
			return {
				Removelog_dialog:false,
				ParyAuthURL_data:{
					bizappid:"",
					clist:null,
					auth_url:""
				},
				url_modal:false,
				fastlogin:{
						"name":"",
						"code": "",
						"legal_persona_wechat":"",
						"legal_persona_name":"",
						"persona_phone":"",
						"component_phone":""
					},
				fastlogin_modal:false,
				Getmsglog_header:[],
				Getmsglog_list:[],
				app_info_modal:false,
				app_info:"",
				state:"全部",
				AuthAppList_header:[],
				AuthAppList_list:[],
				auth_app_config:["全部","创建中","已授权","取消接入","授权取消"],
				del_modal:false,
				name:"",
				current_row:"",
				add_modal:false,
				ComponentInfo: "",
				ruleValidate: {
					name:{ required: true,  trigger: 'blur' },
					code:{ required: true,  trigger: 'blur' },
					legal_persona_wechat:{ required: true,  trigger: 'blur' },
					legal_persona_name:{ required: true,  trigger: 'blur' },
					persona_phone:{ required: true,  trigger: 'blur' },
					component_phone:{ required: true,  trigger: 'blur' },
				}
				
			
			}
		},
		mounted() {

			this.to_GetComponentInfo(this.$route.query)
		},
		methods: {
			async to_Removelog(){
				let pass_data={
					"paryid":this.ComponentInfo.paryid
				}
				let res= await Removelog(pass_data)
				if(res.code!=200){
					return
				}
				if(res.data.acknowledged){
					this.$Message.success(res.data.deletedCount+"")
				}
				this.Removelog_dialog=false
			},
			async to_ParyAuthURL(){
				let pass_data=this.ParyAuthURL_data
				pass_data['paryid']=this.ComponentInfo.paryid
				let res= await ParyAuthURL(pass_data)
				if(res.code!=200){
					return
				}
				this.ParyAuthURL_data.auth_url=res.data.auth_url
			},
			handleReset (name) {
				this.$refs[name].resetFields();
			},
			to_QuicklyCreateApp(){
				this.$refs['fastlogin'].validate(async(valid) => {
					if (valid) {
						let pass_data=this.fastlogin
						pass_data['paryid']=this.ComponentInfo.paryid
						let res= await QuicklyCreateApp(pass_data)
						if(res.code!=200){
							return
						}
						this.fastlogin_modal=false
						this.to_GetComponentInfo(this.$route.query)
					}
				})
				
			},
			async to_ParytclearCache(){
				let pass_data={
					"paryid": this.ComponentInfo.paryid
				}
				await ParytclearCache(pass_data)
				this.to_GetComponentInfo(this.$route.query)
			},
			async to_ComponentAuthAppSync(){
				let pass_data={
					"paryid": this.ComponentInfo.paryid, //内部id
				}
				let res= await ComponentAuthAppSync(pass_data)
				if(res.code!=200){
					return
				}
				
				this.$Message.success('ok')
				this.to_GetComponentInfo(this.$route.query)
				
			},
			async to_ComponentAccessToken(){
				let pass_data={
					"paryid": this.ComponentInfo.paryid, //内部id
				}
				  let res= await ComponentAccessToken(pass_data)
				  if(res.code!=200){
					  return
				  }
					  this.$my_copy(res.data)
				  
				 this.to_GetComponentInfo(this.$route.query)
			},
			async to_ComponentGetmsglog(){
				let pass_data={
					paryid:this.ComponentInfo.paryid
				}
				let {data:res_data}= await ComponentGetmsglog(pass_data)
				 res_data.title.forEach((item,index)=>{
					 if(index<2){
						 item['width']=200
					 }
					if(item.key=='decrypbody'){
						item['slot']='decrypbody'
					}
				})
				this.Getmsglog_header=res_data.title
				this.Getmsglog_list=res_data.list
			},
			async to_GetAuthAppsf(type,row){
				let pass_data={
					"paryid": this.ComponentInfo.paryid, //内部id
					"type": type, //操作类型["获取access_token","获取应用信息"]
					"appid": row.authorizer_appid //要操作的授权应用appid
				}
				  let res= await GetAuthAppsf(pass_data)
				  if(res.code!=200){
					  return
				  }
				  if(type=='获取access_token'){
					  this.$my_copy(res.data)
				  }
				if(type=='获取应用信息'||type=='查询进度'){
					this.app_info=res.data
					this.app_info_modal=true
				}
				  
				 this.to_GetComponentInfo(this.$route.query)
				 this.to_GetAuthAppList()
			},
			async to_GetAuthAppList(){
				let pass_data={
					paryid:this.ComponentInfo.paryid,
					state:this.state
				}
				let {data:res_data}= await GetAuthAppList(pass_data)
				res_data.title.forEach(item=>{
					if(item.key=='image_url'){
						item['slot']='image_url'
					}
				})
				res_data.title.push({
					key:"operation",
					slot:"operation",
					title:"操作",
					width:300
					
				})
				this.AuthAppList_header=res_data.title
				this.AuthAppList_list=res_data.list
			},
			async to_ComponentSetConfig_base(){
				let {AppSecret,Token,EncodingAESKey}=this.ComponentInfo
				let pass_data={
						"paryid": this.ComponentInfo.paryid,
						"type": "基本配置", //操作类型 二选一
						"updata": {
							AppSecret,Token,EncodingAESKey
						}
					}
				 let res= await ComponentSetConfig(pass_data)
				 if(res.code!=200){
					 return
				 }
				 this.to_GetComponentInfo(this.$route.query)
				 this.$Message.success("ok")
			},
			async to_ComponentSetConfig(){
				let pass_data={
						"paryid": this.ComponentInfo.paryid,
						"type": "IP配置", //操作类型 二选一
						"updata": this.ComponentInfo.whiteList
					}
					 let res= await ComponentSetConfig(pass_data)
					 if(res.code!=200){
					 					 return
					 }
					 this.to_GetComponentInfo(this.$route.query)
					 this.$Message.success("ok")
			},
			async to_ComponentApiKey_other(row,type){
				let pass_data={
					paryid:this.ComponentInfo.paryid,
					type:type,
					sid:row.sid
				}
				let res= await ComponentApiKey(pass_data)
				if(res.code!=200){
					return
				}
				this.add_modal=false
				this.to_GetComponentInfo(this.$route.query)
			},
			async to_ComponentApiKey(){
				let pass_data={
					paryid:this.ComponentInfo.paryid,
					type:"添加",
					sid:this.name
				}
				let res= await ComponentApiKey(pass_data)
				if(res.code!=200){
					return
				}
				this.add_modal=false
				this.to_GetComponentInfo(this.$route.query)
			},
			async to_GetComponentInfo(paryid) {
				let {
					data: res_data
				} = await GetComponentInfo(paryid)
				this.ComponentInfo = res_data
			}
		}

	}
</script>

<style>
	.apikey .ivu-form-item {
		margin-bottom: 0px!important;
	}
	
</style>
