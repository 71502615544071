export  let banner = [{
	"uid": "41a22542-7b65-47b2-8bfa-9ca5e814103e",
	"name": "basis",
	"title": "基础配置",
	"icon": "md-alert",
	"child": [{
		"uid": "e37899cc-1829-4d32-9629-7b05fe09a741",
		"title": "门店设置",
		"name": "store_settings",
		"icon": ""
	}, {
		"uid": "53febc30-ba5d-49d4-8187-73395208a068",
		"title": "影厅管理",
		"name": "ting_management",
		"icon": ""
	}, {
		"uid": "92ce498f-0066-48a1-a3da-7055d2a78dab",
		"title": "设备管理",
		"name": "equipment_management",
		"icon": ""
	}]
}, {
	"uid": "d590c8d6-ad09-43a4-ab3b-dc5474b6f855",
	"name": "schedul",
	"title": "排期",
	"icon": "md-film",
	"child": [{
		"uid": "7d78b6e5-8926-47bf-9c36-1c13e08d3dff",
		"title": "票价管理",
		"name": "filmPrice",
		"icon": ""
	}, {
		"uid": "43f54f12-1921-4414-a2ca-aab0ce348de7",
		"title": "片库",
		"name": "db",
		"icon": ""
	}, {
		"uid": "efe93142-1377-479d-bc8c-d0bd6dbc7b07",
		"title": "定价策略",
		"name": "pricing_strategy",
		"icon": ""
	}]
}, {
	"uid": "05ec3c70-effe-464f-a459-639c37d8b27e",
	"name": "vip",
	"title": "会员",
	"icon": "md-people",
	"child": [{
		"uid": "39ce8bf6-ef36-4816-906c-2a4be38e17d7",
		"title": "充值管理",
		"name": "memberPrepaidPhoneButton",
		"icon": ""
	}, {
		"uid": "512aad5c-0949-4931-934f-a3ebc34e6eaf",
		"title": "会员查询",
		"name": "member_the_query",
		"icon": ""
	}, {
		"uid": "4eb290a2-41c2-4134-9b66-07350e5b543d",
		"title": "会员卡查询",
		"name": "membership_card_queries",
		"icon": ""
	}]
}, {
	"uid": "b0bc7957-ebaa-4166-8212-de2dd0fa042a",
	"name": "ordermanage",
	"title": "订单",
	"icon": "md-list-box",
	"child": [{
		"uid": "de6f13d5-8aef-428a-aa1c-30437b959a48",
		"title": "订单查询",
		"name": "order",
		"icon": ""
	}, {
		"uid": "c2a33397-09a1-4727-9199-35d54104a677",
		"title": "退票申请",
		"name": "refund",
		"icon": ""
	}]
}, {
	"uid": "80250175-bd3b-4704-9c29-bab5b3791f7d",
	"name": "goosmanager",
	"title": "卖品",
	"icon": "md-pizza",
	"child": [{
		"uid": "8c4e02ef-6833-4d81-b4b2-7e4e02260434",
		"title": "卖品类型",
		"name": "goods_type",
		"icon": ""
	}, {
		"uid": "f0e39b4f-d85a-4963-8da5-50f76e52d7c2",
		"title": "商品详情",
		"name": "goods_detail",
		"icon": ""
	}]
}, {
	"uid": "377f1ebc-704d-4551-ba98-0dc53b4a8424",
	"name": "report",
	"title": "报表",
	"icon": "md-pie",
	"child": [{
		"uid": "4b2b45c4-5ecf-4419-ba40-e4d3f87ef5df",
		"title": "线下订单统计",
		"name": "offlinePaymentOrders",
		"icon": ""
	}, {
		"uid": "ea99ab2f-c148-4b0c-bdbb-462ca460c29a",
		"title": "票券统计",
		"name": "ticketQuery",
		"icon": ""
	}, {
		"uid": "40abe8aa-d2e5-427d-8d24-0a712a5a0fbc",
		"title": "拉新数据汇总",
		"name": "new_data_summary",
		"icon": ""
	}, {
		"uid": "27239f63-8e1f-4823-aff3-f498501b61e7",
		"title": "电影票销售明细表",
		"name": "schedule_the_ticket_sales",
		"icon": ""
	}, {
		"uid": "ac33f1c7-cb96-4879-a909-15bbf672cef1",
		"title": "商品销售明细",
		"name": "goods_sales_subsidiary",
		"icon": ""
	}]
}, {
	"uid": "1d4db180-95e1-4a75-a8e1-ec7f11ca46f4",
	"name": "ticket_management",
	"title": "票券管理",
	"icon": "md-closed-captioning",
	"child": [{
		"uid": "938ea82f-61f3-4ab5-9ffb-baedd578db5c",
		"title": "票券订单",
		"name": "ticket_order",
		"icon": ""
	}]
}, {
	"uid": "d67bd794-4851-4da2-ab06-cf5971d38c36",
	"name": "distribution_to_promote",
	"title": "分销推广",
	"icon": "",
	"child": [{
		"uid": "bcd55a4c-0d94-4469-ad4f-162a8d8934a1",
		"title": "分销商",
		"name": "distributors",
		"icon": ""
	}]
}]
