import {
	get,
	post
} from "./http";

import ajax_config  from '../../url.json';
let baseUrl;

if (window.location.host.split(':')[0] == 'localhost') {
	baseUrl = ajax_config.devUrl; //开发环境
} else {
	baseUrl = ajax_config.runUrl; //生产环境
}


// 保存入驻表单数据
export const test = (pass_data) => post(baseUrl + "api/cms/test", pass_data);



// 用户登录
export const login = (pass_data) => post(baseUrl + "api/cms/login", pass_data);

// 退出登录
export const logout = (pass_data) => get(baseUrl + "api/cms/logout", {
	params:pass_data
});


// 修改密码
export const UpPass = (pass_data) => post(baseUrl + "api/cms/UpPass", pass_data);

// 退出登录
export const GetUserlist = (pass_data) => get(baseUrl + "api/cms/GetUserlist", {
	params:pass_data
});

// 退出登录
export const ResetPass = (pass_data) => get(baseUrl + "api/cms/ResetPass", {
	params:pass_data
});

// 用户状态修改
export const SetUserState = (pass_data) => post(baseUrl + "api/cms/SetUserState", pass_data);

// 创建用户
export const CreateUser = (pass_data) => post(baseUrl + "api/cms/CreateUser", pass_data);


// 获取基本配置
export const Getbasic = (pass_data) => get(baseUrl + "api/cms/Getbasic", {
	params:pass_data
});

// 设置基本配置
export const Setbasic = (pass_data) => post(baseUrl + "api/cms/Setbasic", pass_data);

// 查询订单
export const queryOrder = (pass_data) => post(baseUrl + "api/cms/queryOrder", pass_data);

// 获取持有用户APP信息数量
export const GetUserAppInfoNumer = (pass_data) => get(baseUrl + "api/cms/GetUserAppInfoNumer", {
	params:pass_data
});


// 创建应用信息
export const CreateAppInfo = (pass_data) => post(baseUrl + "api/cms/CreateAppInfo", pass_data);


// 获取用户应用列表
export const GetUserAppInfoList = (pass_data) => get(baseUrl + "api/cms/GetUserAppInfoList", {
	params:pass_data
});

// 获取应用信息
export const GetAppInfo = (pass_data) => get(baseUrl + "api/cms/GetAppInfo", {
	params:pass_data
});

// 设置应用基本信息
export const SetAppInfo = (pass_data) => post(baseUrl + "api/cms/SetAppInfo", pass_data);


// 设置调用接口IP白名单
export const SetAppIPWite = (pass_data) => post(baseUrl + "api/cms/SetAppIPWite", pass_data);

// 添加授权场景值
export const AddwebAuotScene = (pass_data) => post(baseUrl + "api/cms/AddwebAuotScene", pass_data);

// 删除授权场景值
export const DelwebAuotScene = (pass_data) => post(baseUrl + "api/cms/DelwebAuotScene", pass_data);

// 获取用户微信支付对接列表
export const GetWxPayList = (pass_data) => get(baseUrl + "api/cms/GetWxPayList", {
	params:pass_data
});

// 删除用户微信支付对接信息
export const DelWxPayInfot = (pass_data) => post(baseUrl + "api/cms/DelWxPayInfot", pass_data);


// 获取微信支付参数加密证书
export const GetpublicKey = (pass_data) => get(baseUrl + "api/cms/GetpublicKey", {
	params:pass_data
});

// 应用绑定微信支付信息
export const AppBingWxPayInfo = (pass_data) => post(baseUrl + "api/cms/AppBingWxPayInfo", pass_data);


// 应用解除微信支付绑定信息
export const AppRemoveWxPayInfo = (pass_data) => post(baseUrl + "api/cms/AppRemoveWxPayInfo", pass_data);

// 获取用户可选应用
export const GetUserchooseApp = (pass_data) => get(baseUrl + "api/cms/GetUserchooseApp", {
	params:pass_data
});
// 获取微信支付服务商配置
export const GetWxSPayConfig = (pass_data) => get(baseUrl + "api/cms/GetWxSPayConfig", {
	params:pass_data
});

// 服务商绑定主应用appid
export const SetWxSPayConfig = (pass_data) => post(baseUrl + "api/cms/SetWxSPayConfig", pass_data);

// 获取第三方服务商列表
export const GetParytList = (pass_data) => post(baseUrl + "api/cms/GetParytList", pass_data);

// 获取第三方服务商详情 
export const GetComponentInfo = (pass_data) => post(baseUrl + "api/cms/GetComponentInfo", pass_data);

// 设置第三方服务商调用apikey
export const ComponentApiKey = (pass_data) => post(baseUrl + "api/cms/ComponentApiKey", pass_data);

// 配置第三方服务商信息 
export const ComponentSetConfig = (pass_data) => post(baseUrl + "api/cms/ComponentSetConfig", pass_data);

// 获取第三方服务商授权应用数据
export const GetAuthAppList = (pass_data) => post(baseUrl + "api/cms/GetAuthAppList", pass_data);

// 第三方服务商授权小程序功能接口
export const GetAuthAppsf = (pass_data) => post(baseUrl + "api/cms/GetAuthAppsf", pass_data);

// 服务商接收消息日志
export const ComponentGetmsglog = (pass_data) => post(baseUrl + "api/cms/ComponentGetmsglog", pass_data);

// 获取第三方服务商凭证 
export const ComponentAccessToken = (pass_data) => post(baseUrl + "api/cms/ComponentAccessToken", pass_data);


// 第三方服务商授权应用信息同步
export const ComponentAuthAppSync = (pass_data) => post(baseUrl + "api/cms/ComponentAuthAppSync", pass_data);

// 创建第三方服务商
export const CreateParyt = (pass_data) => post(baseUrl + "api/cms/CreateParyt", pass_data);


// 清空第三方服务token缓存
export const ParytclearCache = (pass_data) => post(baseUrl + "api/cms/ParytclearCache", pass_data);

// 快速注册小程序
export const QuicklyCreateApp = (pass_data) => post(baseUrl + "api/cms/QuicklyCreateApp", pass_data);

// 获取预授权URL
export const ParyAuthURL = (pass_data) => post(baseUrl + "api/cms/ParyAuthURL", pass_data);

// 清空服务商接收日志
export const Removelog = (pass_data) => post(baseUrl + "api/cms/Removelog", pass_data);

// 修改子商户状态
export const SetSubmChid = (pass_data) => post(baseUrl + "api/cms/SetSubmChid", pass_data);

// 服务商添加子商户
export const AddSubmChid = (pass_data) => post(baseUrl + "api/cms/AddSubmChid", pass_data);