<template>
	<div>
		
		<div style="text-align: right;">
			<Button @click="()=>is_create_user_modal=true" type='primary'>创建用户</Button>
		</div>
		
		<div style="margin-top: 20px;">
			<Table :columns='title' :data='list'>
				
				<template slot-scope="{row}" slot='login'>
					
					<i-Switch @on-change="()=>change_switch(row)"  v-model="row.login" true-value='正常' false-value='异常'></i-Switch>
					
				</template>
				
				<template slot-scope="{row}" slot="operation">
					<Button @click="()=>to_ResetPass(row)" size='small'>重置密码</Button>
				</template>
				
			</Table>
		</div>
		
		<!-- 弹窗 -->
		<div>
			<Modal v-model="is_create_user_modal">
				<div>
					<div style="margin-top: 10px;">
						<span style="width: 150px;display: inline-block;">用户名</span>
						<Input  v-model="create_user_form.name" style="width: 200px;"></Input>
					</div>
					<div style="margin-top: 10px;">
						<span style="width: 150px;display: inline-block;">用户user</span>
						<Input v-model="create_user_form.user"  style="width: 200px;"></Input>
					</div>
					<div style="margin-top: 10px;">
						<span style="width: 150px;display: inline-block;">密码</span>
						<Input  v-model="create_user_form.pass" style="width: 200px;" type="password"></Input>
					</div>
					<div style="margin-top: 10px;">
						<span style="width: 150px;display: inline-block;">用户类型</span>
						<Select v-model="create_user_form.type" style="width: 200px;">
							<Option v-for="item in create_user_form_type" :value="item" :key='item'>{{item}}</Option>
						</Select>
					</div>
				</div>
				<div slot='footer'>
					<Button @click="()=>is_create_user_modal=false">返回</Button>
					<Button @click="()=>to_CreateUser()" type='primary'>确定</Button>
				</div>
			</Modal>
		</div>
		
	</div>
</template>

<script>
	
	import { md5 } from '../../lib/js/common/md5.js'
	import { GetUserlist,ResetPass,SetUserState,CreateUser } from '../../request/api.js'
	export default{
		name:'页面_用户设置',
		data() {
			return {
				create_user_form_type:['普通类型','管理员'],//创建用户配置
				create_user_form:{
					"name": "", //用户名
					"user": "", //用户user
					"pass": "", //密码MD5加密
					"type": "" //用户类型["普通类型","财务类型","管理员"]
				},
				is_create_user_modal:false,//是否弹出创建用户对话框
				list:[],
				title:[]
			}
		},
		mounted() {
			this.to_GetUserlist()
		},
		methods:{
			
			async to_CreateUser(){
				
				let pass_data = {
					"name": this.create_user_form.name, //用户名
					"user":  this.create_user_form.user, //用户user
					"pass":  md5(this.create_user_form.pass), //密码MD5加密
					"type": this.create_user_form.type //用户类型["普通类型","财务类型","管理员"]
					}
					
					let res_data = await CreateUser(pass_data)
					
					if(res_data.code==200){
						this.is_create_user_modal=false
						this.to_GetUserlist()
					}
		
				
			},
			
			// 修改用户状态
			async change_switch(row){
				this.list.forEach(item=>{
					if(row.uid==item.uid){
						item.login = row.login
					}
				})
				
				let pass_data = {
					"uid": row.uid, 
					"state": row.login=='正常'?true:false
				}
				
				let res_data = await  SetUserState(pass_data)
				
				if(res_data.code==200){
					this.$mytoast(res_data.msg)
				}
		
				
			},
			
			// 重置密码
			async to_ResetPass(row){
				console.log(row.uid,'==============35')
				let pass_data = {
					"uid": row.uid
				}
				
				let res_data = await ResetPass(pass_data)
				
				console.log(res_data,'=================42')
				
				
			},
			async to_GetUserlist(){
				
				let { data: res_data } = await GetUserlist()
				
				res_data.title.push({
					title:'操作',
					slot:'operation',
					key:'operation'
				})
				
				res_data.title.forEach(item=>{
					if(item.key=="login"){
						item.slot='login'
					}
				})
				
				this.title = res_data.title
				this.list = res_data.list
				
			},
		}
	}
</script>

<style>
</style>
