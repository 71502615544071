<template>
	<div>
		<div style="text-align: right;">

		</div>

		<div>
			<h1 style="margin-top: 10PX;padding-left: 10PX;display: flex;align-items: center;">微信支付对接列表
				<Button style="margin-left: 20px;" @click="()=>is_show_add_dialog=true">添加</Button>
			</h1>

			<div style="margin-top: 10px;">

				<Row>
					<Col style="padding: 10px;" :key="item.sid" v-for="(item,index) in WxPayList" span="8">
					<Card>

						<div style="text-align: right;color: #29a878;display: flex;justify-content: space-between;">
							<span></span><span @click="()=>to_DelWxPayInfot(item)"
								style="margin-left: 10px;text-decoration: underline;cursor: pointer;">删除</span>
						</div>
						<div style="display: flex;margin-bottom: 10px;">
							<span style="display: inline-block;width: 100px;">{{item.type}}</span>
							<div>{{item.name}}({{item.mchid}})</div>
						</div>
						<div style="display: flex;margin-bottom: 10px;color: #ccc;">
							<span style="display: inline-block;width: 100px;">创建时间</span>
							<div>{{item.creationTime}}</div>
						</div>
						<div style="display: flex;margin-bottom: 10px;align-items: center;">
							<span style="display: inline-block;width: 100px;">sid</span>
							<div>{{item.sid}}</div>
							<Icon @click="()=>$my_copy(item.sid)" style="cursor: pointer;"
								type="ios-browsers-outline" />
						</div>
						<div style="display: flex;margin-bottom: 10px;align-items: center;">
							<span style="display: inline-block;width: 100px;">apikey</span>
							<Input v-model="item.apikey" password type="password" style="width: 300px;"></Input>
							<Icon @click="()=>$my_copy(item.apikey)" style="cursor: pointer;margin: 0 20px;"
								type="ios-browsers-outline" />
						</div>
						<div style="display: flex;margin-bottom: 10px;align-items: center;">
							<span style="display: inline-block;width: 100px;">publicKey</span>
							<div>
								<span v-if="item.publicKey" style="color: #29a878;">已获取</span>
								<span v-else style="color: #ccc;">未获取</span>
							</div>

							<Button @click="()=>to_GetpublicKey(item)" style="margin:0 20px;"
								size="small">{{item.publicKey==''?"获取":"刷新"}}</Button>
							<Button @click="()=>(set_dialog=true)&&(current_app_list=item)" size="small"
								v-if="item.type=='服务商'">配置</Button>

						</div>

					</Card>
					</Col>
				</Row>

			</div>
		</div>

		<!-- 弹框 -->
		<div>
			<Modal @on-visible-change="($)=>full_dialog_visible_change($,'formline')" fullscreen v-model="set_dialog">
				<div>
					<Form ref="formline" :model="formline" label-position="left" :label-width="100">
						<FormItem prop="appid" label="主APPID">
							<Select v-model="formline.appid" style="width: 250px;margin-right: 10px;">
								<Option v-for="item in UserchooseApp_list" :key="item.lable" :value="item.value"
									:label="item.label"></Option>
							</Select>
							<Button style="margin-right: 10px;" @click="()=>to_SetWxSPayConfig()">绑定</Button>
							<Button @click="()=>showModalChild()">添加子商户</Button>
						</FormItem>
					</Form>
					<h2>当前主APPID ({{PayConfig.appid}})</h2>
					<Table :columns='PayConfig.title' :data='PayConfig.child'>
						<template slot-scope="{row}" slot="details">
							<Poptip transfer>
								<Button>详情</Button>
								<div class="api" slot="content">
									<div>子appid：{{row.details.subappid}}</div>
									<div>apikey：{{row.details.apikey}}</div>
									<div>交易回调url：{{row.details.payurl}}</div>
									<div>退款回调url：{{row.details.refurl}}</div>
								</div>
							</Poptip>
							 <Poptip
									transfer
							        confirm
							        title="确认是否删除?"
							        @on-ok="()=>tableRowdelete(row)">
							        <Button class="white">删除</Button>
							    </Poptip>
							
						</template>
						<template slot-scope="{row}" slot="stop">
							<i-Switch v-model="row.stop" @on-change="()=>swichForbiden(row)"></i-Switch>
						</template>
						
					</Table>

				</div>
				<div slot="footer">
					<Button @click="()=>set_dialog=false">返回</Button>
					<Button>确定</Button>
				</div>
			</Modal>
			<Modal :mask-closable='false' v-model="is_show_add_dialog">

				<div>
					<h1 style="margin-bottom: 20px;">创建微信支付对接信息</h1>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">商户名</span>
						<Input v-model="WxPayInfo_obj.name" style="width: 250px;"></Input>
					</div>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">商户号</span>
						<Input v-model="WxPayInfo_obj.mchid" style="width: 250px;"></Input>
					</div>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">账号类型</span>
						<Select style="width: 250px;" v-model="WxPayInfo_obj.type">
							<Option v-for="item in type" :key="item" :value="item" :label="item"></Option>
						</Select>
					</div>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">apiv3密钥</span>
						<Input v-model="WxPayInfo_obj.apiv3" style="width: 250px;"></Input>
					</div>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">apiv2密钥</span>
						<Input v-model="WxPayInfo_obj.apiv2" style="width: 250px;"></Input>
					</div>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">支付成功通知URL</span>
						<Input v-model="WxPayInfo_obj.payurl" style="width: 250px;"></Input>
					</div>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">退款成功通知URL</span>
						<Input v-model="WxPayInfo_obj.refurl" style="width: 250px;"></Input>
					</div>
					<div>
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">证书序列号</span>
						<Input v-model="WxPayInfo_obj.serial_no" style="width: 250px;"></Input>
					</div>
					<div style="display: flex;">
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">cert证书</span>
						<Upload action="" :show-upload-list="true" :before-upload="handleUpload_cert">
							<span>{{zs_name.cert}}</span>
							<Button style="margin-left: 10px;">上传</Button>
						</Upload>
					</div>
					<div style="display: flex;">
						<span style="width: 200px;display: inline-block;margin-bottom: 20px;">key证书</span>
						<Upload action="" :show-upload-list="true" :before-upload="handleUpload_key">
							<span>{{zs_name.key}}</span>
							<Button style="margin-left: 10px;">上传</Button>
						</Upload>
					</div>
				</div>


				<div slot="footer">
					<Button @click="()=>is_show_add_dialog=false">返回</Button>
					<Button @click="()=>to_CreateWxPayInfo()">确定</Button>
				</div>
			</Modal>
			<Modal :mask-closable='false' v-model="is_show_addChild_dialog">
				<div style="padding: 20px;">
					<Form ref="formChild" :model="formChild" label-position="left" :label-width="100">
						<FormItem prop="name" label="name">
							<Input v-model="formChild.name"></Input>
						</FormItem>
						<FormItem prop="subappid" label="子appid">
							<Input v-model="formChild.subappid"></Input>
						</FormItem>
						<FormItem prop="submchid" label="子商户号">
							<Input v-model="formChild.submchid"></Input>
						</FormItem>
						<FormItem prop="payurl" label="支付回调URL">
							<Input v-model="formChild.payurl"></Input>
						</FormItem>
						<FormItem prop="refurl" label="退款回调URL">
							<Input v-model="formChild.refurl"></Input>
						</FormItem>
					</Form>
				</div>
				<div slot="footer">
					<Button @click="()=>is_show_addChild_dialog=false">返回</Button>
					<Button @click="()=>childSubmit()">确定</Button>
				</div>
			</Modal>
		</div>
	</div>
</template>

<script>
	import hs from '../../../url.json'
	import {
		md5
	} from '../../lib/js/common/md5.js'
	import FormData from 'form-data'
	import axios from 'axios'
	import {
		GetWxPayList,
		DelWxPayInfot,
		GetpublicKey,
		GetUserchooseApp,
		GetWxSPayConfig,
		SetWxSPayConfig,
		SetSubmChid,
		AddSubmChid
	} from '@/request/api.js'
	export default {
		name: '页面_微信支付',
		data() {
			return {
				formChild:{
					sid:"",
					name:"",
					subappid:"",
					submchid:"",
					payurl:"",
					refurl:"",
				},
				is_show_addChild_dialog:false,
				tableData: "",
				PayConfig: "",
				current_app_list: "",
				UserchooseApp_list: [],
				formline: {
					appid: ""
				},
				set_dialog: false,
				type: ['服务商', '商户号'],
				WxPayList: [], //已有添加列表
				zs_name: {
					cert: '',
					key: ''
				}, //文件上传名字回显
				WxPayInfo_obj: {
					"type": "商户号",
					"name": "",
					"mchid": "",
					"apiv3": "",
					"apiv2": "",
					"payurl": "",
					"refurl": "",
					"serial_no": "",
				},
				formData: new FormData(), //格式化提交表单对象
				time: '', //签名时间
				is_show_add_dialog: false, //添加对话框
			}
		},

		mounted() {

			this.to_GetWxPayList()
		},
		watch:{
			is_show_addChild_dialog(value){
				if(!value){
					this.reset("formChild")
				}
			}
		},

		methods: {
			/**
			 * 禁用切换
			 */
			async swichForbiden(value){
				const {peid} = value
				let res = await SetSubmChid({
					"peid": peid, //子商户peid
					"type": "禁用修改" //删除  禁用修改 二选一
				})
				if (res.code != 200) return
				this.full_dialog_visible_change(true)
			},
			reset(name){
				this.$refs[name].resetFields()
			},
			/**
			 * 添加子商户
			 */
			async childSubmit(){
				this.formChild.sid = this.PayConfig.sid
				let res = await AddSubmChid(this.formChild)
				if (res.code != 200) return
				this.full_dialog_visible_change(true)
				this.is_show_addChild_dialog =false
			},
			/**
			*弹出添加子商户窗口
			 */
			showModalChild(){
				this.is_show_addChild_dialog = true
			},
			/**
			 * 表格删除
			 */
			async tableRowdelete(value){
				const {peid} = value
				let res = await SetSubmChid({
					"peid": peid, //子商户peid
					"type": "删除" //删除  禁用修改 二选一
				})
				if (res.code != 200) return
				this.full_dialog_visible_change(true)
			},
			async to_SetWxSPayConfig() {
				let pass_data = this.formline
				pass_data['sid'] = this.PayConfig.sid
				let res = await SetWxSPayConfig(pass_data)
				if (res.code != 200) {
					return
				}
				let pass_data1 = {
					sid: this.current_app_list.sid
				}
				let {
					data: res_data_config
				} = await GetWxSPayConfig(pass_data1)
				res_data_config.title.forEach(item => {
					if (item.key == "details") {
						item["slot"] = "details"
					}
					if (item.key == "stop") {
						item["slot"] = "stop"
					}
				})
				this.PayConfig = res_data_config
			},
			async full_dialog_visible_change($, name) {
				if ($) {
					let {
						data: res_data
					} = await GetUserchooseApp()
					this.UserchooseApp_list = res_data
					let pass_data = {
						sid: this.current_app_list.sid
					}
					let {
						data: res_data_config
					} = await GetWxSPayConfig(pass_data)
					res_data_config.title.forEach(item => {
						if (item.key == "details") {
							item["slot"] = "details"
						}
						if (item.key == "stop") {
							item["slot"] = "stop"
						}
					})
					this.PayConfig = res_data_config
				}
				if (name) {
					this.$refs[name].resetFields()
				}

			},
			// 获取微信支付参数加密证书
			async to_GetpublicKey(item) {

				let pass_data = {
					"sid": item.sid //此sid为对接微信支付生成的对接编号sid
				}

				let res_data = await GetpublicKey(pass_data)

				if (res_data.code == 200) {
					this.$mytoast("获取成功")
					this.to_GetWxPayList()
				}

			},

			// 删除用户微信支付对接信息
			async to_DelWxPayInfot(item) {

				let pass_data = {
					"sid": item.sid //此sid为对接微信支付生成的对接编号sid
				}

				let res_data = await DelWxPayInfot(pass_data)

				if (res_data.code == 200) {
					this.to_GetWxPayList()
				}

			},

			// 获取用户微信支付对接列表
			async to_GetWxPayList() {

				let {
					data: res_data
				} = await GetWxPayList()

				this.WxPayList = res_data;

			},

			// 添加微信支付对接列表
			async to_CreateWxPayInfo() {

				let host = hs.devUrl
				for (let i in this.WxPayInfo_obj) {
					this.formData.set(i, this.WxPayInfo_obj[i])
				}
				let {
					data: res_data
				} = await axios.post(`${host}api/cms/CreateWxPayInfo`, this.formData)

				if (res_data.code != 200) {
					return
				}
				this.is_show_add_dialog = false
				this.$mytoast('添加成功')
				this.to_GetWxPayList()

			},

			handleUpload_key(file) {

				this.formData.set("key", file)
				this.zs_name.key = file.name
				return false;

			},

			handleUpload_cert(file) {
				this.formData.set("cert", file)
				this.zs_name.cert = file.name
				return false;
			},

			handleSubmit(name) {
				console.log(name)
			}

		}
	}
</script>

<style scoped>
	.white.ivu-btn {
	    background-color: #fff!important;
	    color: #000!important;
	    border: 1px solid #dcdee2!important;
		margin-left: 10px;
	}
</style>
