<template>
	<div>
		<div style="text-align: right;line-height: 50px;">
			<Button @click="add_model=true">添加</Button>
		</div>
		<Row>
			<Col style="padding: 10px;" v-for="item in ParytList" span="8">
			<Card>
				<Form label-position='left' :label-width="130">
					<FormItem label="服务商">
						{{item.name}}
					</FormItem>
					<FormItem style="color: green;" label="状态">
						{{item.state}}
					</FormItem>
					<FormItem label="appid">
						{{item.APPID}}
					</FormItem>
					<FormItem label="应用接入量">
						{{item.appnum}}/{{item.appmax}}
					</FormItem>
					<FormItem label="调用key创建量">
						{{item.keynum}}/{{item.keymax}}
					</FormItem>

				</Form>
				<div style="position: absolute;top: 10px;right: 10px;color: #ccc">
					{{item.creationTime}}
				</div>
				<div style="position: absolute;bottom: 10px;right: 10px;">
					<Button @click="()=>$router.push('/manage?paryid='+item.paryid)">进入管理</Button>
				</div>
			</Card>
			</Col>
		</Row>
		<Modal :mask-closable='false' v-model="add_model">
			<div slot="footer">
				<div style="width: 400px;">
					<Form ref="formValidate" label-position='left' :rules="ruleValidate" :label-width="150">
						<FormItem label="服务商名称">
							<Input v-model="Paryt_.name"></Input>
						</FormItem>
						<FormItem label="服务商appid">
							<Input v-model="Paryt_.APPID"></Input>
						</FormItem>
						<FormItem label="服务商开发AppSecret">
							<Input v-model="Paryt_.AppSecret"></Input>
						</FormItem>
						<FormItem label="服务商消息验证Token">
							<Input v-model="Paryt_.Token"></Input>
						</FormItem>
						<FormItem label="EncodingAESKey">
							<Input v-model="Paryt_.EncodingAESKey"></Input>
						</FormItem>
					</Form>
				</div>
				<Button @click="()=>add_model=false">取消</Button>
				<Button @click="()=>to_CreateParyt()">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		GetParytList,
		CreateParyt,
		ParyAuthURL
	} from '@/request/api'
	export default {
		name: "third_party_platform",
		data() {
			return {
				Paryt_:{
					"name": "", //服务商名称
					"APPID": "", //服务商appid
					"AppSecret": "", //服务商开发AppSecret
					"Token": "", //服务商消息验证Token
					"EncodingAESKey": "" //服务商消息解密EncodingAESKey
				},
				add_model: false,
				ParytList: [],
				ruleValidate: {
					name: {required: true,trigger: 'blur'}
				}
			}
			},
			mounted() {
					this.to_GetParytList()
				},
				methods: {
					async to_CreateParyt(){
						let pass_data=this.Paryt_
						
						let res= await CreateParyt(pass_data)
						if(res.code!=200){
							return
						}
						this.add_model=false
						this.to_GetParytList()
					},
					async to_GetParytList() {
						let {
							data: res_data
						} = await GetParytList()
						this.ParytList = res_data
					}
				}
		}
</script>

<style>
	.ivu-form-item {
		margin-bottom: 5px;
	}
</style>
