<template>
	<div>
		
		<Card>
			
			<div style="display: flex;margin-bottom: 10px;">
				<span style="display: inline-block;width: 100px;">用户名</span>
				<div>{{$userInfo.name}}</div>
			</div>
			<div style="display: flex;margin-bottom: 10px;">
				<span style="display: inline-block;width: 100px;">用户类型</span>
				<div>{{$userInfo.type}}</div>
			</div>
			<div style="display: flex;margin-bottom: 10px;">
				<span style="display: inline-block;width: 100px;">应用创建上限</span>
				<div v-if="AppInfoNumer">{{AppInfoNumer.Num}}/{{AppInfoNumer.keyPair}}</div>
			</div>
			
		</Card>
		
		<h1 style="margin-top: 10PX;padding-left: 10PX;">App信息
			<Button @click="()=>is_show_create_app_modal=true">创建</Button>
		</h1>
		
		<div style="margin-top: 10px;">
			<Row>
				<Col style="padding: 10px;" :key="item.sid"  v-for="(item,index) in UserAppInfoList" span="8">
					<Card>
						
						<div style="text-align: right;color: #29a878;">
							<span @click="()=>(is_show_edit_modal=true)&&(to_GetAppInfo(item))&&(to_GetWxPayList())" style="cursor: pointer; text-decoration: underline;">编辑</span>
							<span style="margin-left: 10px;text-decoration: underline;cursor: pointer;">删除</span>
						</div>
						<div style="display: flex;margin-bottom: 10px;">
							<span style="display: inline-block;width: 100px;">应用名称</span>
							<div>{{item.name}}({{item.type}})</div>
						</div>
						<div style="display: flex;margin-bottom: 10px;color: #ccc;">
							<span style="display: inline-block;width: 100px;">创建时间</span>
							<div>{{item.creationTime}}</div>
						</div>
						<div style="display: flex;margin-bottom: 10px;align-items: center;">
							<span style="display: inline-block;width: 100px;">sid</span>
							<div>{{item.sid}}</div>
							<Icon @click="()=>$my_copy(item.sid)"  style="cursor: pointer;" type="ios-browsers-outline" />
						</div>
						<div style="display: flex;margin-bottom: 10px;align-items: center;">
							<span style="display: inline-block;width: 100px;">apikey</span>
							<Input   v-model="item.apikey"  password type="password"  style="width: 300px;"></Input>
							<Icon @click="()=>$my_copy(item.apikey)"  style="cursor: pointer;margin: 0 20px;" type="ios-browsers-outline" />
							<!-- <Button size='small'>显示</Button> -->
						</div>
						
					</Card>
				</Col>
			</Row>
		</div>
		
		<div>
			
			<Modal v-model="is_show_create_app_modal">
				
				<div style="display: flex;margin-bottom: 10px;align-items: center;">
					<span style="display: inline-block;width: 100px;">APP名称</span>
					<Input v-model="create_app_modal_obj.name" style="width: 200px;"></Input>
				</div>
				
				<div style="display: flex;margin-bottom: 10px;align-items: center;">
					<span style="display: inline-block;width: 100px;">APP类型</span>
					<Select v-model="create_app_modal_obj.type" style="width: 200px;">
						<Option :key="item" v-for="item in  create_app_modal_config" :value="item">{{item}}</Option>
					</Select>
				</div>
				<div style="display: flex;margin-bottom: 10px;align-items: center;">
					<span style="display: inline-block;width: 100px;">appid</span>
					<Input v-model="create_app_modal_obj.appid" style="width: 200px;"></Input>
				</div>
				<div style="display: flex;margin-bottom: 10px;align-items: center;">
					<span style="display: inline-block;width: 100px;">secretkey</span>
					<Input v-model="create_app_modal_obj.secretkey" style="width: 200px;"></Input>
				</div>
				<div slot="footer">
					<Button @click="()=>is_show_create_app_modal=false">取消</Button>
					<Button @click="()=>to_CreateAppInfo()">确定</Button>
				</div>
				
			</Modal>
			
			<Modal fullscreen  v-model="is_show_edit_modal">
				<div>
					<div style="display: flex;margin-bottom: 10px;">
						<span style="display: inline-block;width: 100px;">sid</span>
						<div>{{current_select_App_Edit_Info.sid}}</div>
					</div>
					<div style="display: flex;margin-bottom: 10px;">
						<span style="display: inline-block;width: 100px;">应用类型</span>
						<div>{{current_select_App_Edit_Info.type}}</div>
					</div>
					<div style="display: flex;margin-bottom: 10px;">
						<span style="display: inline-block;width: 100px;">创建时间</span>
						<div>{{current_select_App_Edit_Info.creationTime}}</div>
					</div>
					
				</div>
				<div>
					<Tabs type="card">
					        <TabPane label="基本配置">
								<div style="display: flex;margin-bottom: 10px;align-items: center;">
									<span style="display: inline-block;width: 100px;">APP名称</span>
									<Input v-model="current_select_App_Edit_Info.name" style="width: 300px;"></Input>
								</div>
								
								<div style="display: flex;margin-bottom: 10px;align-items: center;">
									<span style="display: inline-block;width: 100px;">appid</span>
									<Input v-model="current_select_App_Edit_Info.appid" style="width: 300px;"></Input>
								</div>
								<div style="display: flex;margin-bottom: 10px;align-items: center;">
									<span style="display: inline-block;width: 100px;">secretkey</span>
									<Input type="password" v-model="current_select_App_Edit_Info.secretkey" style="width: 300px;"></Input>
								</div>
								<div style="display: flex;margin-bottom: 10px;align-items: center;">
									<span style="display: inline-block;width: 100px;">authfileName</span>
									<Upload :on-success="handleSuccess" :show-upload-list="false" name="authfile" :headers="{'sid':'A765492831880','signature':SignaTure({}).signature,'time':time,'token':$token}" :action="`//${host}api/cms/UpAppauthfile`">
										<span style="margin-right: 10px;">{{current_select_App_Edit_Info.authfileName}}</span><Button>上传</Button>
									</Upload>
								</div>
								<div style="text-align: right;">
									<Button @click="()=>to_SetAppInfo()">确定修改</Button>
								</div>
								
							</TabPane>
					        <TabPane label="调用IP白名单">
								   <div style="margin-bottom: 10px;">在IP白名单内的IP地址作为来源，应用接口才可调用成功，需要配置多个,输入IP后进行换行输入</div>
								   <Input v-model="current_select_App_Edit_Info.white" type="textarea" :rows="10" placeholder="Enter something..." />
								   <div style="text-align: right;margin-top: 10px;;">
									   <Button @click="()=>to_SetAppIPWite()">提交</Button>
								   </div>
							</TabPane>
					        <TabPane label="授权场景" >
								
								<div style="display: flex;justify-content: space-around;">
									<div style="width: 300px;height: 50px;"></div>
									<Input placeholder="请输入rurl" v-model="sesen.rurl" style="width: 300px;"></Input>
									<Input placeholder="请输入note" v-model="sesen.note" style="width: 300px;"></Input>
									<Select v-model="sesen.scope"  style="width: 300px;">
										<Option v-for="item in scope_config" :value="item">{{item}}</Option>
									</Select>
									<div style="width: 50px;">
										<Button @click="()=>to_AddwebAuotScene()"  style="">添加</Button>
									</div>
								</div>
								<div  style="display: flex;justify-content: space-around;margin-top: 10px;">
									<h1 style="width: 300px;">seid(授权场景ID)</h1>
									<h1 style="width: 300px;">rurl(回调路径)</h1>
									<h1 style="width: 300px;">note(备注)</h1>
									<h1 style="width: 300px;">scope(授权类型)</h1>
									
									<div style="width: 50px;"></div>
								</div>
								<div v-for="(item,index) in current_select_App_Edit_Info.webAuot" style="display: flex;justify-content: space-around;margin-top: 10px;">
									<div style="width: 300px;">{{item.seid}}<Icon @click="()=>$my_copy(item.seid)"  style="cursor: pointer;" type="ios-browsers-outline" /></div>
									<div style="width: 300px;">{{item.rurl}}</div>
									<div style="width: 300px;">{{item.note}}</div>
									<div style="width: 300px;">{{item.scope}}</div>
									
									<Button @click="()=>to_DelwebAuotScene(item)" >删除</Button>
								</div>
								
							</TabPane>
							<TabPane label="微信支付关联">
								 <Alert type="warning" show-icon>只能关联微信支付商户号，无法关联微信支付服务商</Alert>
								  <div style="display: flex;justify-content: space-around;">
									  <div style="width: 300px;height: 50px;"></div>
								    <Select v-model="WxPay_select_and_input.paysid" style="width: 300px;">
									  <Option v-for="item in WxPayList" :value="item.sid">{{item.name}}({{item.sid}})</Option>
								    </Select>
								  	<Input placeholder="请输入payurl(可空)" v-model="WxPay_select_and_input.payurl"   style="width: 300px;"></Input>
								  	<Input placeholder="请输入refurl(可空)" v-model="WxPay_select_and_input.refurl" style="width: 300px;"></Input>
								  	
								  	<Button @click="()=>to_AppBingWxPayInfo()"  style="margin-left: 20px;">添加</Button>
								  </div>
								  <div  style="display: flex;justify-content: space-around;margin-top: 10px;">
								  	<h1 style="width: 300px;">peid(关联支付ID)</h1>
								  	<h1 style="width: 300px;">关联微信支付</h1>
								  	<h1 style="width: 300px;">支付成功通知URL</h1>
								  	<h1 style="width: 300px;">退款成功通知URL</h1>
								  	
								  	<div style="width: 50px;height: 20px;"></div>
								  </div>
								  <div v-for="(item,index) in current_select_App_Edit_Info.wxpay" style="display: flex;justify-content: space-around;margin-top: 10px;">
								  	<div style="width: 300px;">{{item.peid}}<Icon @click="()=>$my_copy(item.peid)"  style="cursor: pointer;" type="ios-browsers-outline" /></div>
								  	<div style="width: 300px;">{{item.name}}({{item.sid}})</div>
								  	<div style="width: 300px;">{{item.payurl}}</div>
								  	<div style="width: 300px;">{{item.refurl}}</div>
								  	<!-- <div style="width: 50px;height: 20px;"></div> -->
								  	<Button style="width: 50px;" @click="()=>to_AppRemoveWxPayInfo(item)" >删除</Button>
								  </div>
								  
							</TabPane>
					    </Tabs>
				</div>
				<div slot="footer">
				
				</div>
			</Modal>
			
		</div>
		
	</div>
</template>

<script>
	
	import { md5 } from '../../lib/js/common/md5.js'
	import hs from '../../../url.json'
	import { AppRemoveWxPayInfo,AppBingWxPayInfo,GetWxPayList,GetUserAppInfoNumer,CreateAppInfo,GetUserAppInfoList,GetAppInfo,SetAppInfo,SetAppIPWite,AddwebAuotScene,DelwebAuotScene } from '../../request/api.js'
	
	export default{
		name:'页面_应用管理',
		data() {
			return {
				
				WxPay_select_and_input:{
					"paysid": '', //绑定微信支付对接信息的sid
					"payurl": '', //微信支付成功回调通知URL，可空，空默认使用微信支付的配置信息支付成功回调url
					"refurl": '' //微信退款成功回调通知URL，可空，空默认使用微信支付的配置信息退款成功回调url
					},
				WxPayList:[],//微信支付对接列表
				scope_config:['snsapi_userinfo','snsapi_base'],
				sesen:{
					"scope": "", 
					"rurl":  '',
					"note": '' 
					},
				time:'',
				host:'',
				current_select_App_Edit_Info:"",//当前操作的APP数据
				is_show_edit_modal:false,//是否显示编辑对话框
				UserAppInfoList:[],//用户应用列表
				create_app_modal_config:["公众号","小程序","企业微信"],
				create_app_modal_obj:{
				"name": "", 
				"type": "", 
				"appid": "", 
				"secretkey": "" 
				},
				is_show_create_app_modal:false,
				AppInfoNumer:'',
			}
		},
	
		mounted(){
			
			this.host = hs.devUrl.split('//')[1]
			this.to_GetUserAppInfoNumer()
			
		},
		methods:{
			
			// 应用解除微信支付绑定信息
			async to_AppRemoveWxPayInfo(item){
				
				let pass_data = {
					"appsid":this.current_select_App_Edit_Info.sid,
					"peid":item.peid
				}
				
				let res_data = await AppRemoveWxPayInfo(pass_data)
				
				if(res_data.code==200){
					this.to_GetAppInfo(this.current_select_App_Edit_Info)
				}
				
				
			},
			
			// 应用绑定微信支付信息
			async to_AppBingWxPayInfo(){
				
				let pass_data = this.WxPay_select_and_input
				pass_data["appsid"] = this.current_select_App_Edit_Info.sid
				
				let res_data = await AppBingWxPayInfo(pass_data)
				
				if(res_data.code==200){
					this.to_GetAppInfo(this.current_select_App_Edit_Info)
				}
				
				
			},
			
			// 获取用户微信支付对接列表
			async to_GetWxPayList(){
				
				let { data: res_data} = await GetWxPayList()
				
				this.WxPayList = res_data;
				
			},
			
			// 删除授权场景值
			async to_DelwebAuotScene(item){
				
				let pass_data = {
				"sid": this.current_select_App_Edit_Info.sid, 
				"seid": item.seid 
				}
				
				let res_data = await  DelwebAuotScene(pass_data)
				
				if(res_data.code==200){
					this.to_GetAppInfo(this.current_select_App_Edit_Info)
					this.$mytoast("删除成功")
				}
				
			},
			
			// 添加授权场景值
			async to_AddwebAuotScene(){
				
				let pass_data = this.sesen
				pass_data['sid'] = this.current_select_App_Edit_Info.sid
				
				let res_data = await AddwebAuotScene(pass_data)
				
				if(res_data.code==200){
					this.to_GetAppInfo(this.current_select_App_Edit_Info)
					this.$mytoast("添加成功")
				}
				
				
			},
			
			// 设置调用接口IP白名单
			async to_SetAppIPWite(){
				
				let pass_data ={
					"sid": this.current_select_App_Edit_Info.sid, 
					"white": this.current_select_App_Edit_Info.white
					}
				
				let res_data = await SetAppIPWite(pass_data)
				
				if(res_data.code==200){
					this.to_GetAppInfo(this.current_select_App_Edit_Info)
					this.$mytoast("保存成功")
				}
				
			},
			
			// 设置应用基本信息
			async to_SetAppInfo(){
				
				let pass_data = this.current_select_App_Edit_Info
				
				let res_data = await SetAppInfo(pass_data)
				
				if(res_data.code==200){
					this.to_GetAppInfo(this.current_select_App_Edit_Info)
					this.$mytoast('保存成功')
				}
				
			},
			
			handleSuccess (res, file) {
				
				if(res.code!=200){
					this.$mytoast_err(res.msg)
					return
				}
				this.current_select_App_Edit_Info.authfileName = res.data.name

		    },
			
			
			SignaTure(parameter, key='9fb821f90e4c4274a427f9740f30cde6',time=null) {
			 let _s = Object.keys(parameter).sort();
			 let sortjson = {}
			 for (let a of _s) {
			  sortjson[a] = parameter[a]
			 }
			 time = time?time:new Date().getTime()
			 this.time = time
			 let s = JSON.stringify(sortjson)
			 return {
			  time,
			  signature: md5(md5(s) + key + time)
			 }
			},
			
			// 获取应用信息
			async to_GetAppInfo(item){
			
				let pass_data = {
					"sid":item.sid
				}
				
			   let { data: res_data } = await GetAppInfo(pass_data)
			   
			   this.current_select_App_Edit_Info = res_data
				
			},
			
			// 获取用户应用列表
			async to_GetUserAppInfoList(){
				
				let { data: res_data} = await GetUserAppInfoList()
				
				this.UserAppInfoList = res_data.list
				
			},
			
			// 创建应用信息
			async to_CreateAppInfo(){
				
				let pass_data = this.create_app_modal_obj;
				
				let res_data = await CreateAppInfo(pass_data)
				if(res_data.code==200){
					this.to_GetUserAppInfoNumer()
					this.$mytoast(res_data.msg)
					this.is_show_create_app_modal = false
				}
				
			},
			
			
			// 获取持有用户APP信息数量
			async to_GetUserAppInfoNumer(){
				
				let { data: res_data} = await GetUserAppInfoNumer()
				this.AppInfoNumer = res_data
				
				this.to_GetUserAppInfoList()
				
			},
			
		}
		
	}
</script>

<style>
</style>
