import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import user_settings from '../views/content/user_settings.vue'
import application_management from '../views/content/application_management.vue'
import wxpay from '../views/content/wxpay.vue'
import third_party_platform from '../views/content/third_party_platform.vue'
import manage from '../views/content/manage.vue'
import nofound from "../components/error-page/404.vue"

Vue.use(VueRouter)

const routes = [
	{
		  path: '/404',
		  component:nofound
	},
	{
	  path: '*',
	  redirect: '/404'
	},
	{
	  path: '/',
	  redirect:'/login'
	},
  {
    path: '/home',
    component: Home,
	children:[
		{
		  path: '/manage',
		  component:manage
		},
		{
		  path: '/third_party_platform',
		  component:third_party_platform
		},
		{
		  path: '/wxpay',
		  component:wxpay
		},
		{
		  path: '/application_management',
		  component:application_management
		},
		{
		  path: '/user_settings',
		  component:user_settings
		},
	]
  },
  {
    path: '/login',
    component: Login
  }

]

const router = new VueRouter({
	mode:'history',
  routes
})

router.beforeEach(async (to,from,next)=>{
	
	let token = localStorage.getItem('token')
	let userInfo = localStorage.getItem('userInfo')
	Vue.prototype.$token = '';
	Vue.prototype.$userInfo = '';
	if(token){
		Vue.prototype.$token = token
	}
	if(userInfo){
		Vue.prototype.$userInfo = JSON.parse(userInfo)
	}
	
	
	next()
})

export default router
