import ajax_config  from '../../url.json';
import { api_show_not_200 } from './lib/common.js'
import { md5 } from '../lib/js/common/md5.js'
let Auth  = ajax_config.Auth;// 准入令牌【约定】字符
let back_to_login_code = ajax_config.back_to_login_code;// 返回登录页【约定】状态码


/**
 *置token入请求协议头
 * @param {Object}  config [置入token的地址位置]
 */

export const request_config=(config)=>{
	
		config.headers['sid'] = 'A765492831880'
		if (localStorage.getItem(Auth)) {
			config.headers[Auth] = localStorage.getItem(Auth);
		
		}
	
	   //签名
	   let parameter = {};
	   // post请求
	   if(config.data){		
		   if(Object.prototype.toString.call(config.data) == '[object FormData]'){
			   let s = config.data.entries()
			   let i = s.next()
			   while (!i.done){
				if(!(i.value[1] instanceof File)){
				parameter[i.value[0]]=i.value[1]			
				}
				i = s.next()
			   } 
		   }else{
			    parameter = config.data;
		   }
		  
		  
	   }
	   // get请求
	   if(config.params){
		   parameter = config.params;
	   }
		let obj = JSON.parse(JSON.stringify(config.headers))
		let objb =  SignaTure(parameter,'9fb821f90e4c4274a427f9740f30cde6')
	  
	   config.headers={
		   ...obj,
		   ...objb
	   }
	    
	
	
	
}


/**
 * 响应码判断
 * @param {Object} response [判断该对象携带的响应码] 
 */

export const response_config=(response)=>{
	
	// 这个抛出到error
	if(response.status!=200){
		let err_msg = response.data.message
		throw new Error(err_msg);
	}else{
		if (response.data && response.data.code != 200) {
				console.log('================40')
			// -----后端返回的状态码 退出登录-----
			if (response.data.code==back_to_login_code) {
				window.location.replace('/')
					
			}else{
				// 错误消息提示
				let err_msg = response.data.msg
				api_show_not_200(err_msg)
			}
					
		}
	}
	
	
}

function SignaTure(parameter, key,time=null) {
 let _s = Object.keys(parameter).sort();
 let sortjson = {}
 for (let a of _s) {
  sortjson[a] = parameter[a]
 }
 time = time?time:new Date().getTime()
 let s = JSON.stringify(sortjson)
 return {
  time,
  signature: md5(md5(s) + key + time)
 }
}