import axios from "axios";
axios.defaults.timeout = 20000;
import {show_loading,hide_loading,lose_token_back_to_login } from './lib/common.js'
require('./interceptors')


/**
 * get请求
 * @param {String} url 请求地址
 * @param {Object} params 请求参数
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		// 菊花现	
		show_loading()

		axios
			.get(
				url, params
			)
			.then((res) => {
				// 菊花隐藏
				hide_loading()
				
				// token丢失打回登录页函数
				lose_token_back_to_login()
				
				
				resolve(res.data);


			})
			.catch((err) => {
		
				// 菊花隐藏
				hide_loading()
				reject(err);
			});
	});
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
	return new Promise((resolve, reject) => {
		// 菊花现
		show_loading()
		
		axios
			.post(url, params)
			.then((res) => {
				// 菊花隐藏
				hide_loading()
				
				// token丢失打回登录页函数
				lose_token_back_to_login()
				
				

				resolve(res.data);

			})
			.catch((err) => {
				// 菊花隐藏
				hide_loading()
				// console.log(err.message,'==================1')
				// console.log(err.config ,'==================2')
				// console.log(err.request ,'==================3')
				// console.log(err.response ,'==================4')
				// console.log(err.isAxiosError ,'===============5===')
				// console.log(err.toJSON()  ,'==================6')
				// if(err.message.indexOf(401)!=-1){
				// 	console.log('========================asdfasdf')
				// }
						
				// for(let item in err){
				// 	console.log('==============73')
				// 	console.log(item,'===============70')
				// }
				
				reject(err);
			});
	});
}
