import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.config.productionTip = false

Vue.use(ViewUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$mytoast_err = (pass_data)=>{
	Vue.prototype.$Message.error(pass_data)
}	

Vue.prototype.$mytoast = (pass_data)=>{
	Vue.prototype.$Message.success(pass_data)
}	

// 剪切板功能函数
Vue.prototype.$my_copy = (content, message) => {
	var aux = document.createElement("input");
	aux.setAttribute("value", content);
	document.body.appendChild(aux);
	aux.select();
	document.execCommand("copy");
	document.body.removeChild(aux);
	if (message == null) {
		Vue.prototype.$mytoast('复制成功')
	} else {
		Vue.prototype.$mytoast_err(message)
	}
}


// 该函数必须不能再create 里面使用
Vue.prototype.$show_loading = (pass_info) => {
	Vue.nextTick(()=>{
		document.getElementById('loading').innerHTML = `
		<div   style="width:100vw;height:100vh;position: absolute;top: 50%;left: 50%;z-index: 999999;transform: translate(-50%,-50%);text-align: center;">
				<div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
					<div >
						<img src="${dialog_config['加载动画']}" >
					</div>
					<span style="color: green;" >
						${pass_info?pass_info:''}
					</span>
				</div>
		</div>
		`
	})

	
}; //显示菊花加载图标

Vue.prototype.$hide_loading = () => {
	document.getElementById('loading').innerHTML = ''
}; //菊花加载图标

